import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import "./blogdetail.css";
import parse from 'html-react-parser';

const Blogdetail = () => {
  var value = JSON.parse(localStorage.getItem("blog"));
  return (
    <>
      <Header />
      <div class="max-w-screen-xl mx-auto">
        <main class="blogdetail">
            <div className="blogname text-center">
              <p>{value.title}</p>
            </div>
          <div class="mb-4 md:mb-0 w-full max-w-screen-md mx-auto relative">
            <div class="flex mb-2">
              <img
                src={`http://localhost:8080/${value.authorimage}`}
                class="h-10 w-10 rounded-full mr-5 object-cover"
              />
              <div className="namecreate">
                <p class="authname-detail text-gray-200 text-sm">
                  {value.authorname}
                </p>
                <p class=" authcreate text-gray-400 text-xs">
                {value.createdAt !== undefined ?
                              <>
                              {new Date(value.createdAt).toLocaleTimeString("en-US")}
                              &nbsp;&nbsp;|&nbsp;&nbsp; 
                              {new Date(value.createdAt).toDateString("en-US") }
                              </>
                              :null}
                </p>
              </div>
            </div>
          </div>
          <div
            class="mb-4 md:mb-0 w-full max-w-screen-md mx-auto relative"
            style={{ height: "24em" }}
          >
            <img
              src={`http://localhost:8080/${value.image}`}
              class="absolute left-0 top-0 w-full h-full z-0 object-cover"
            />
           
          </div>

          <div class="px-4 lg:px-0 mt-12 text-gray-700 max-w-screen-md mx-auto text-lg leading-relaxed">
            <div class="pb-6">
           
          
            {parse(value.discription)}
           
            </div>

          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};
export default Blogdetail;
